import React from "react"
import PrimaryLayout from "../layouts/PrimaryLayout"
import { Form, Button } from "react-bootstrap"

const Contact = () => (
  <>
    <PrimaryLayout  column="col-10">
      <h1>Contact us</h1>
      <Form className="">
        <Form.Group controlId="contactForm.Email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Email..." />
        </Form.Group>
        <Form.Group controlId="contactForm.Subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control type="email" placeholder="subject..." />
        </Form.Group>
        <Form.Group controlId="contactForm.Message">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows="3" />
        </Form.Group>
        <Form.Group controlId="contactForm.Submit">
          <Button>Send</Button>
        </Form.Group>
      </Form>
    </PrimaryLayout>
  </>
)

export default Contact
